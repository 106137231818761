body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 0 !important;
}

.modalOn {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  scrollbar-width: thin !important;
  scrollbar-color: #969696 #181a1b !important;

  -webkit-tap-highlight-color: transparent !important;
  outline: none !important;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px !important;
}

*::-webkit-scrollbar-track {
  background: #181a1b !important;
}

*::-webkit-scrollbar-thumb {
  background-color: #969696 !important;
  border-radius: 10px !important;
  border: 3px solid #181a1b !important;
}

.loading {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000086;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
}

.MuiPopover-root {
  z-index: 9999999999999999 !important;
}

:root {
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  ) !important;

  --toastify-color-progress-success: var(--toastify-color-progress-light) !important;
}

.chip-weapon {
  background-color: #b02323 !important;
}
.chip-armadure {
  background-color: #2348b0 !important;
}
.chip-addon {
  background-color: #2389b0 !important;
}
.chip-ammo {
  background-color: #32b023 !important;
}
.chip-general {
  background-color: #de8121 !important;
}
.chip-inventory {
  background-color: #1972be !important;
}
.chip-store {
  background-color: #6f19be !important;
}
.chip-ground {
  background-color: #be1919 !important;
}
.chip-entity {
  background-color: #be19b9 !important;
}
.chip-masterHold {
  background-color: #ff36a5 !important;
}