.App {
  display: flex;
  flex-direction: column;
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.button-small {
  border: 0;
  height: 30px;
  font-size: 12px;
  padding: 0 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.1s;
}

.button-mid {
  border: 0;
  height: 35px;
  font-size: 14px;
  padding: 0 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.1s;
}

.button-big {
  border: 0;
  height: 40px;
  font-size: 16px;
  padding: 0 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.1s;
}

.button-small:hover, .button-mid:hover, .button-big:hover {
  opacity: 0.8;
  transition: 0.1s;
}

.button-blue {
  background-color: #3e3eab;
  color: white;
}

.button-white {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}

.button-green {
  background-color: #3cca54;
  color: rgb(0, 0, 0);
}

.button-green-blue {
  background-color: #60d3d3;
  color: rgb(0, 0, 0);
}